import React, {useState} from 'react';
import { Routes, Route, Link } from 'react-router-dom';
import ProductionNetwork from './ProductionNetwork';
import InputCategorization from './InputCategorization';
import Tetris from './Tetris';
import './App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartSimple } from '@fortawesome/free-solid-svg-icons';
import { faCity } from '@fortawesome/free-solid-svg-icons';
import { faEarthAmericas } from '@fortawesome/free-solid-svg-icons';
import { faDatabase } from '@fortawesome/free-solid-svg-icons';
import { faCode } from '@fortawesome/free-solid-svg-icons';
import { faMap } from '@fortawesome/free-solid-svg-icons';
import { faPersonChalkboard } from '@fortawesome/free-solid-svg-icons';
import { faCircleNodes } from '@fortawesome/free-solid-svg-icons';
import { faBolt } from '@fortawesome/free-solid-svg-icons';
import { faSolarPanel } from '@fortawesome/free-solid-svg-icons';
import { faSquare } from '@fortawesome/free-solid-svg-icons';
import { faDiagramProject } from '@fortawesome/free-solid-svg-icons';
import { faOilWell } from '@fortawesome/free-solid-svg-icons';
import headshot from './stu-headshot.jpg';
import mpStudyAreas from './mp_study_areas_overview.jpg';
import mpResLidar from './mp_residential_lidar_data_points.jpg';
import mpResTIN from './mp_residential_tin_model.jpg';
import mpResDSM from './mp_residential_digital_surface_model.jpg';
import mpResSolarRadiation from './mp_residential_solar_radiation.jpg';
import mpResNDVI from './mp_residential_ndvi.jpg';
import mpResSuitableArea from './mp_residential_suitable_pv_area.jpg';
import isoneWindOutputWindSpeed from './isone_windoutput_windspeed.svg';
import isoneWindOutputWindGustSpeed from './isone_windoutput_windgustspeed.svg';
import isoneSolarOutputFairWeather from './isone_solaroutput_fairweather.svg';
import isoneNetImportsWindOutput from './isone_netimports_windoutput.svg';
import isoneNetImportsSolarOutput from './isone_netimports_solaroutput.svg';
import isoneExcel from './isone_windsolar_weather_netimports_exploration.xlsx';
import graphData from './production_network.json';
import categoryData from './input_categorization.csv';


class TopBar extends React.Component {
  render() {
    return (
      <div className="menuContainer">
        <div className="menu">
          <span className="topbar_name"><a href="/">Stuart Iler</a></span>
          <ul>
            <li className="topbar_menu"><a href="/#about">ABOUT</a></li>
            <li className="topbar_menu"><a href="/#skills">SKILLS</a></li>
            <li className="topbar_menu"><a href="/#tools">TOOLS</a></li>
            <li className="topbar_menu"><a href="/#projects">PROJECTS</a></li>
            <li className="topbar_menu"><a href="/#contact">CONTACT</a></li>
          </ul>
        </div>
      </div>
    );
  }
}

class About extends React.Component {
  render() {
    return (
      <div id="about_background">
        <div id="about" className="section_ID"></div>
        <div className="spacer"></div>
        <div className="intro_text">
          <div className="title_text">
            <p><b>STUART ILER</b></p>
            <p>Data Scientist ● Economist</p>
            <p>Leveraging data, analysis, and visualization to provide insights and create positive impact.</p>
          </div>
          <img src={headshot} />
          <div>
            <p><b>Welcome!</b></p>
            <p>I'm a mission-driven data scientist and economist with a wide variety of experience managing, analyzing, visualizing, and communicating data.</p>
            <p>I specialize in topics related to public policy and economics, with a particular focus on energy, climate, and sustainability. </p>
          </div>
        </div>
        <div className="intro_text2">
          <div>
            <p><b>Professional Background</b></p>
            <p>My work experience spans nonprofit, academic, government, and private contexts, including as an analytics manager, software engineer, policy analyst, researcher, and consultant.</p>
            <p>For details about my previous positions and publications, please see my LinkedIn profile <a href="https://www.linkedin.com/in/stuartiler" target="_blank">here</a>. For sample projects and related code, please see below as well as my GitHub profile <a href="https://github.com/stuartiler/" target="_blank">here</a>.</p>
          </div>
          <div>
            <p><b>Education</b></p>
            <ul>
              <li>
                Ph.D. in Public Policy (Harvard University 2022) (<a href="https://dash.harvard.edu/bitstream/handle/1/37372205/Stuart%20Iler%20Dissertation%20Final.pdf" target="_blank">dissertation</a>)
              </li>
              <li>
                Master of Environmental Management (Duke University 2012) (<a href="https://dukespace.lib.duke.edu/dspace/bitstream/handle/10161/5326/Iler_Community_Solar.pdf" target="_blank">thesis</a>)
              </li>
              <li>B.S. in Computer Science (U.C. San Diego 2006)</li>
            </ul>
          </div>
        </div>
        <div className="spacer"></div>
        <div className="spacer"></div>
      </div>
    );
  }
}

class Skills extends React.Component {
  render() {
    return (
      <div id="skills_background">
        <div id="skills" className="section_ID"></div>
        <div className="section_header">
          <p>SKILLS</p>
        </div>
        <div className="section_subheader">
          <p>I have experience working across the data science lifecycle, including data acquisition, cleaning, modeling, output creation, and communications.</p>
        </div>
        <div className="skills">
          <div>
            <span className="icon"><FontAwesomeIcon icon={faDatabase} size="2x" /></span>
            <span>Using econometric and machine learning techniques to extract insights from data and make predictions.</span>
          </div>
          <div>
            <span className="icon"><FontAwesomeIcon icon={faChartSimple} size="2x" /></span>
            <span>Creating static and interactive data visualizations to track progress, understand trends, and analyze relationships.</span>
          </div>
          <div>
            <span className="icon"><FontAwesomeIcon icon={faCity} size="2x" /></span>
            <span>Working with a spectrum of datasets ranging from energy and air quality to employment, manufacturing, and transportation.</span>
          </div>
          <div>
            <span className="icon"><FontAwesomeIcon icon={faMap} size="2x" /></span>
            <span>Using Geographic Information Systems (GIS) technologies to conduct spatial analyses and visualize spatial data.</span>
          </div>
          <div>
            <span className="icon"><FontAwesomeIcon icon={faEarthAmericas} size="2x" /></span>
            <span>Researching and analyzing laws and regulations related to renewable fuels, oil/gas extraction, and greenhouse gas emissions.</span>
          </div>
          <div>
            <span className="icon"><FontAwesomeIcon icon={faPersonChalkboard} size="2x" /></span>
            <span>Presenting and teaching about topics at the intersection of computer science, economics, and energy and environment.</span>
          </div>
        </div>
        <div className="spacer"></div>
        <div className="spacer"></div>
      </div>
    );
  }
}

class Tools extends React.Component {
  render() {
    return (
      <div id="tools_background">
        <div id="tools" className="section_ID"></div>
        <div className="section_header">
          <p>TOOLS</p>
        </div>
        <div className="section_subheader">
          <p>I have extensive experience with a variety of programming languages
          and software tools.</p>
        </div>
        <div className="tools">
            <div>
              <div>
                <span className="icon"><FontAwesomeIcon icon={faCode} size="1x" /></span>
                <span><b>R</b></span>
              </div>
              <div><i>dplyr</i>, <i>ggplot2</i>, and other <i>tidyverse</i> packages for data importation, cleaning, management, and visualization; <i>caret</i> for predictive modeling.</div>
            </div>
            <div>
              <div>
                <span className="icon"><FontAwesomeIcon icon={faCode} size="1x" /></span>
                <span><b>Python</b></span>
              </div>
              <div><i>pandas</i> for data importation, cleaning, and management; <i>NumPy</i> and <i>NetworkX</i> for matrix operations and network analysis; <i>scikit-learn</i> for predictive modeling; object-oriented programming for economic simulation.</div>
            </div>
            <div>
              <div>
                <span className="icon"><FontAwesomeIcon icon={faCode} size="1x" /></span>
                <span><b>Stata</b></span>
              </div>
              <div>Data importation, cleaning, management, and visualization; econometrics and unsupervised machine learning.</div>
            </div>
            <div>
              <div>
                <span className="icon"><FontAwesomeIcon icon={faCode} size="1x" /></span>
                <span><b>Visualization</b></span>
              </div>
              <div>Interactive data visualizations with <i>D3.js</i>, <i>Tableau</i>, and <i>Power BI</i>, including maps, network diagrams, and line and bar charts.</div>
            </div>
            <div>
              <div>
                <span className="icon"><FontAwesomeIcon icon={faCode} size="1x" /></span>
                <span><b>GIS</b></span>
              </div>
              <div>Analysis using a variety of vector and raster data, such as lidar, satellite imagery, and building outlines.</div>
            </div>
            <div>
              <div>
                <span className="icon"><FontAwesomeIcon icon={faCode} size="1x" /></span>
                <span><b>Databases</b></span>
              </div>
              <div>Working with relational databases using <i>SQL</i>; previous experience with <i>PostgreSQL</i> and <i>Microsoft Access</i>.</div>
            </div>
        </div>
        <div className="spacer"></div>
        <div className="spacer"></div>
      </div>
    );
  }
}

class Projects extends React.Component {
  render() {
    return (
      <div id="projects_background">
        <div id="projects" className="section_ID"></div>
        <div className="section_header">
          <p>PROJECTS</p>
        </div>
        <div className="section_subheader">
          <p>These are a few sample projects that highlight some of my technical, analytical, and visualization skills.
          Source code for most of these projects is available on <a href="https://github.com/stuartiler/" target="_blank">GitHub</a>.</p>
        </div>
        <div className="projects">
            <div>
              <Link to="/production_network">
                <div className="icon"><FontAwesomeIcon icon={faCircleNodes} size="2x" /></div>
                <div>Exploring connections in the U.S. economic production network with D3.js</div>
              </Link>
            </div>
            <div>
              <Link to="/input_categorization">
                <div className="icon"><FontAwesomeIcon icon={faOilWell} size="2x" /></div>
                <div>Categorizing U.S. industries' use of petroleum products with Python, R, and D3.js</div>
              </Link>
            </div>
            <div>
              <Link to="/community_solar">
                <div className="icon"><FontAwesomeIcon icon={faSolarPanel} size="2x" /></div>
                <div>Assessing the potential for community solar in Durham, NC with ArcGIS</div>
              </Link>
            </div>
            <div>
              <Link to="/isone_weather">
                <div className="icon"><FontAwesomeIcon icon={faBolt} size="2x" /></div>
                <div>Exploring ISO New England wind/solar power as it relates to weather and net imports with Excel</div>
              </Link>
            </div>
            <div>
              <Link to="/tetris">
                <div className="icon"><FontAwesomeIcon icon={faSquare} size="2x" /></div>
                <div>For fun: Tetris with JavaScript and D3.js</div>
              </Link>
            </div>
            <div>
              <Link to="/production_model">
                <div className="icon"><FontAwesomeIcon icon={faDiagramProject} size="2x" /></div>
                <div>Simulating economic production networks with Python</div>
              </Link>
            </div>
        </div>
        <div className="spacer"></div>
        <div className="spacer"></div>
      </div>
    );
  }
}

class Contact extends React.Component {
  render() {
    return (
      <div id="contact_background">
        <div id="contact" className="section_ID"></div>
        <div className="section_header">
          <p>CONTACT</p>
        </div>
        <div className="section_subheader">
          <p>If you're interested in working with me or learning more about
          my projects, please contact me on <a href="https://www.linkedin.com/in/stuartiler" target="_blank">LinkedIn</a>.
          Thank you for visiting!</p>
        </div>
        <div className="spacer"></div>
        <div>Built with <a href="https://reactjs.org/" target="_blank">React</a>. Hosted by <a href="https://www.digitalocean.com/" target="_blank">DigitalOcean</a>. Icons from <a href="https://fontawesome.com/" target="_blank">Font Awesome</a>.</div>
        <div className="spacer"></div>
      </div>
    );
  }
}

class MainPage extends React.Component {
  render() {
    return (
      <div>
        <About />
        <Skills />
        <Tools />
        <Projects onProjectSelect={this.props.handleSelect} />
        <Contact />
      </div>
    );
  }
}

class ExploreVisTopText extends React.Component {
  render() {
    return (
      <div>
        <div className="explanatory_text">
          <h3>Exploring the U.S. economic production network</h3>

          <p>A production network is a conceptualization of the economy that
          explicitly takes account of the linkages between producing entities
          (like firms and industries). In such models, producing entities
          purchase inputs from other producing entities, combine those inputs
          to create an output, and then sell that output to other entities.
          Production networks provide a unique lens through which to understand
          how changes to one part of the economy can affect other parts of the
          economy.</p>

          <p>The custom, interactive data visualization below&mdash;built
          with <a href="https://d3js.org/" target="_blank">D3.js v7</a>&mdash;allows
          users to explore the U.S. economic production network
          as it was in 2015. In this visualization, the producing entities are
          U.S. industries, and specifically, are the 71 industries (including
          government) contained within the U.S. Bureau of Economic
          Analysis' <a href="https://www.bea.gov/industry/input-output-accounts-data" target="_blank">input-output tables</a> for
          2015. As shown in the visualization, each of these industries is
          associated with a code. For example, the code for the "Farms"
          industry is "111CA."</p>

          <p>The visualization works by focusing on a single industry at a time.
          This "focus" industry is shown in red in the center of the
          visualization, while its direct suppliers (i.e., the industries it
          purchases from) are shown in orange above it and its direct customers
          (i.e., the industries it sells to) are shown in green below it. A
          pair of industries may have a mutual dependence such that they
          purchase from, and sell to, each other. As an example, the "Farms"
          industry (code 111CA) purchases from, and sells to, the "Chemical
          products" industry (code 325). Where these types of relationships
          occur, the relevant suppliers/customers are shown in purple to the
          right of the focus industry. For the sake of clarity, the
          visualization is limited to displaying the top five suppliers and the
          top five customers for each focus industry (where, as described, a
          particular industry's supplier may also be its customer, and in that
          case the supplier/customer is shown in purple to the side).</p>

          <p><b>Hover</b> over an industry to see more information. <b>Click</b> on
          an industry to change the focus.</p>
        </div>
      </div>
    )
  }
}

class ExploreVisBottomText extends React.Component {
  render() {
    return (
      <div>
        <div className="explanatory_text">
          <p>A focus industry's direct suppliers and customers (its
          "first-degree" connections) capture just one aspect of its
          connectedness within the broader U.S. production network. The
          visualization also displays some "second-degree" connections:
          a focus industry's suppliers' suppliers as well as its customers'
          customers. These connections are shown in grey at the top and the
          bottom of the visualization, respectively.</p>

          <p>Finally, on the right is a beeswarm plot that illustrates each
          industry's "upstreamness." Although all industries have connections
          throughout the U.S. production network, some industries tend to sell
          most (or potentially all) of their output to final consumers rather
          than to other industries. Upstreamness is essentially a measure of
          how far away an industry is from this type of final demand, where a
          greater upstreamness value reflects a greater distance. For this
          visualization, I use an upstreamness definition based
          on <a href="https://www.aeaweb.org/articles?id=10.1257/aer.102.3.412" target="_blank">Antràs et al. (2012)</a>,
          which yields upstreamness values for the 71 industries ranging from
          one (most downstream) to 4.5 (most upstream). The most upstream
          industry is "Support activities for mining" (code 213), while there
          are several industries tied for least upstream, including "Hospitals"
          (code 622), "Housing" (code HS), and "Food and beverage stores" (code
          445).</p>
        </div>
      </div>
    )
  }
}

class ExploreVis extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <div className="spacer"></div>
        <div className="spacer"></div>
        <ExploreVisTopText />
        <ProductionNetwork graph={this.props.graphData} />
        <ExploreVisBottomText />
        <div className="spacer"></div>
        <div className="spacer"></div>
        <div>Source code for this visualization is available
        on <a href="https://github.com/stuartiler/network-visualization" target="_blank">GitHub</a>.</div>
        <div className="spacer"></div>
      </div>
    );
  }
}

class ExploreCatTopText extends React.Component {
  render() {
    return (
      <div>
        <div className="explanatory_text">
          <h3>Categorizing and exploring U.S. industries' use of petroleum
          products</h3>

          <p>As illustrated by the "<Link to="/production_network">exploring
          production networks</Link>" visualization, the U.S. economy can be
          thought of as a network of producing entities, where each entity
          purchases inputs from other producing entities, combines those
          inputs to create an output, and then sells that output to other
          entities downstream. One of the advantages of the production networks
          conceptualization is that it provides a lens through which to explore
          the propagation of shocks, and one of the key factors determining how
          such shocks are transmitted is the ability (or inability) of producing
          entities to switch among their inputs.</p>

          <p>To explore these dynamics in more detail, I consider here how U.S.
          industries use the outputs of the petroleum products industry
          <a href="#star">*</a> (such as gasoline and diesel fuel) in relation
          to their other inputs. I choose the petroleum products industry
          specifically both because it is a central input in the production
          network and because its price has historically (and even recently)
          been quite volatile.</p>

          <p>Many industries use petroleum products as a <i>complement</i> to
          other inputs, in the sense that when they use more petroleum
          products, they also tend to use more of those other inputs. As an
          example, the wood products industry might combine the outputs of the
          forestry industry and petroleum products in a complementary way, given
          that they need fuel to run the machinery that converts harvested
          timber into finished goods (such as lumber and flooring). Conversely,
          some industries may use petroleum products as a <i>substitute</i> for
          other inputs, in the sense that when they use more petroleum products,
          they tend to use less of those other inputs. For example, the rail
          transportation industry might use the outputs of the utilities
          industry (such as electric power) and petroleum products as
          substitutes, given that some of their operations may be able to switch
          between different types of energy sources.</p>

          <h4>Price shock propagation: upstream to downstream</h4>

          <p>Price changes tend to move from upstream to downstream in the
          production network (that is, from suppliers to customers). The reason
          is that when a producing entity faces a price change, it may pass
          through some of that change to its customers, which then do the same
          with their own customers, and so on. Previous research suggests that
          when inputs are used as complements, it may lead to greater price
          shock propagation than when inputs are used as substitutes. The
          intuition is that, in the complements case, producing entities have
          less flexibility to switch among their inputs when prices fluctuate,
          which may lead them to modify the prices of their own products and
          services more than they otherwise would. This dynamic is then
          compounded over multiple levels in the production network, which leads
          to greater shock propagation.</p>

          <p>The interactive data visualization below&mdash;built
          with <a href="https://d3js.org/" target="_blank">D3.js v7</a>&mdash;allows
          users to explore the proportions of U.S. industries' upstream inputs
          that have been categorized as complements to petroleum products,
          substitutes for petroleum products, or neither. The categorizations
          are based on my <a href="https://dash.harvard.edu/bitstream/handle/1/37372205/Stuart%20Iler%20Dissertation%20Final.pdf" target="_blank">dissertation research</a>,
          which proposes an approach to generate these types of categorizations
          using machine learning and publicly available data (this visualization
          makes use of data from
          the <a href="https://www.bea.gov/" target="_blank">U.S. Bureau of Economic Analysis</a>).
          The visualization takes into account two degrees of upstream
          connections: industries' direct suppliers as well as those suppliers'
          suppliers.</p>

          <p>As shown in the chart, a large portion of industries' upstream
          inputs are not categorized as either complements to or substitutes for
          petroleum products. The reason is that, essentially, the approach did
          not detect a strong enough usage pattern for those inputs vis-a-vis
          petroleum products. For additional details, see the full Python, R,
          and D3.js source code for the categorization and visualization
          on <a href="https://github.com/stuartiler/input-categorization" target="_blank">GitHub</a>.</p>

          <p><b>Hover</b> over each bar to see more information. <b>Click</b> on
          the buttons above the chart to re-sort based on each category.</p>
        </div>
      </div>
    )
  }
}

class ExploreCatMiddleText extends React.Component {
  render() {
    return (
      <div>
        <div className="explanatory_text">
          <p>One pattern to note is that most industries use petroleum products
          as a complement to at least some of their other inputs. This provides
          a partial explanation for why historical oil price increases have had
          substantial economic effects throughout the production network even
          when individual industries' expenditures on petroleum products have
          been relatively small. Re-sorting the chart by substitutes shows that
          many (though not all) of the industries using petroleum products as a
          substitute to other inputs are service industries.</p>

          <h4>Demand shock propagation: downstream to upstream</h4>

          <p>Unlike prices, demand changes tend to move from downstream to
          upstream in the production network (that is, from customers to
          suppliers). The reason is that when a producing entity faces a demand
          change, it tends to increase or decrease demand for the inputs
          provided by its suppliers, which those suppliers may then do with
          their own suppliers, and so on.</p>

          <p>When industries demand more petroleum products, they will also tend
          to demand more of the inputs that they use as complements to petroleum
          products. The industries that make those complementary products then
          also experience an increase in demand. Conversely, when industries
          demand less petroleum products, they will tend to demand more of the
          inputs that they use as substitutes for petroleum products. The
          industries that produce those substitute products will then experience
          an increase in demand.</p>

          <p>The visualization below shows the proportions of U.S. industries'
          downstream customers that use their ouput as complements to petroleum
          products, substitutes for petroleum products, or neither (as
          categorized by the same approach described above). Also similar to
          above, the visualization takes account of two degrees of downstream
          connections: industries' direct customers as well as those customers'
          customers.</p>

          <p>The key takeaway is that industries with a greater proportion of
          downstream complements will tend to experience higher demand when
          there is higher demand for petroleum products (and vice-versa), while
          industries with a greater proportion of downstream substitutes will
          tend to experience higher demand when there is lower demand for
          petroleum products (and vice-versa). Note that a number of industries'
          outputs are used as complements by some of their customers and as
          substitutes by others; depending on the situation, these industries
          may face conflicting forces.</p>

          <p><b>Hover</b> over each bar to see more information. <b>Click</b> on
          the buttons above the chart to re-sort based on each category.</p>
        </div>
      </div>
    )
  }
}

class ExploreCat extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <div className="spacer"></div>
        <div className="spacer"></div>
        <ExploreCatTopText />
        <InputCategorization category_csv_data={this.props.catData} up_or_down={"upstream"} index={0} />
        <ExploreCatMiddleText />
        <InputCategorization category_csv_data={this.props.catData} up_or_down={"downstream"} index={1} />
        <div className="spacer"></div>
        <div className="spacer"></div>
        <div id="star">*This analysis focuses on the 3-digit NAICS industry
        "petroleum and coal products," which for simplicity I refer to as
        "petroleum products."</div>
        <div>Source code for this visualization is available
        on <a href="https://github.com/stuartiler/input-categorization" target="_blank">GitHub</a>.</div>
        <div className="spacer"></div>
      </div>
    );
  }
}

class TetrisTopText extends React.Component {
  render() {
    return (
      <div>
        <div className="explanatory_text">
          <h3>Tetris with JavaScript and D3.js</h3>

          <p>Below is an interactive Tetris game I built with JavaScript
          and <a href="https://d3js.org/" target="_blank">D3.js v7</a>. Source
          code for game is available
          on <a href="https://github.com/stuartiler/d3-tetris" target="_blank">GitHub</a>.</p>

          <p>Press the arrow keys the on the keyboard to control the falling
          piece. <b>Left</b> and <b>right</b> will move the piece left and
          right, respectively. <b>Up</b> will rotate the piece clockwise,
          and <b>down</b> will push the piece as far down as it can go. (Note
          that scrolling the browser window with the arrow keys has been
          disabled for this page.)</p>

          <p>When one or more horizontal lines are completed, the lines will
          disappear and the remaining blocks will be shifted down. The score
          is increased ten points for every line cleared, with a bonus for the
          number of lines cleared simultaneously.</p>

          <p>If the board becomes completely filled with blocks, it's game over
          and everything is reset.</p>
        </div>
      </div>
    )
  }
}

class PlayTetris extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <div className="spacer"></div>
        <div className="spacer"></div>
        <TetrisTopText />
        <div className="spacer"></div>
        <Tetris />
        <div className="spacer"></div>
        <div className="spacer"></div>
        <div>Source code for the game is available
        on <a href="https://github.com/stuartiler/d3-tetris" target="_blank">GitHub</a>.</div>
        <div className="spacer"></div>
      </div>
    );
  }
}

class CommunitySolar extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <div className="explanatory_text">
          <div className="spacer"></div>
          <div className="spacer"></div>

          <h3>Assessing the potential for community solar in Durham, NC</h3>

          <p>For
          my <a href="https://dukespace.lib.duke.edu/dspace/bitstream/handle/10161/5326/Iler_Community_Solar.pdf" target="_blank">master's thesis</a> at
          Duke University, I used Geographic Information Systems (GIS) to
          explore the potential for community solar in Durham, North Carolina
          (where Duke is located). I present here a high-level overview of the
          methods, results, and conclusions of the study.</p>

          <p>Community solar can be defined as "a solar-electric system that,
          through a voluntary program, provides power and/or financial benefit
          to, or is owned by, multiple community members"
          (<a href="https://www.nrel.gov/docs/fy11osti/49930.pdf" target="_blank">Coughlin et al., 2010</a>).
          Two of the primary goals of community solar are to increase access to
          solar energy and to reduce initial costs. Other potential benefits
          include better economies of scale, increased flexibility in choosing
          an optimal solar site, and the possibility of local job creation.</p>

          <h4>Methods</h4>

          <p>I explored community solar in the project by:
            <ol>
              <li>Estimating the cost and solar potential of hypothetical systems across approximately 2,000 parcels in Durham; and</li>
              <li>Analyzing the differences in cost and solar potential by type of parcel.</li>
            </ol>
          The idea was to proxy for the benefits of community solar vis-a-vis
          individually-owned residential rooftop systems by considering
          commercial and community service parcels on one hand (where community
          solar projects are more likely to be located) and residential parcels
          on the other.</p>

          <p>To do this, I first chose three study areas (see image below): a
          residential area north of Duke University’s East Campus; the greater
          downtown Durham area; and the majority of Duke University’s West
          Campus.</p>

          <div className="spacer"></div>
          <img src={mpStudyAreas} />
          <div className="spacer"></div>

          <p>My next task was to estimate the cost and solar potential of
          hypothetical PV systems on the parcels across these areas. I started
          by obtaining Light Detection and Ranging (LIDAR) data for the study
          areas from the U.S. Geological Survey. LIDAR provides accurate
          elevation measurements by using laser and positioning technology from
          the air.</p>

          <p>Below is an example of the LIDAR data points (in yellow) on and
          around an elementary school in the residential study area. Note that
          the data points represent the elevation of the highest object at each
          point, such as a building, tree, or the ground.</p>

          <div className="spacer"></div>
          <img src={mpResLidar} />
          <div className="spacer"></div>

          <p>I used these points to create a Triangulated Irregular Network
          (TIN) model: a 3-dimensional surface where individual points are
          connected by edges to generate a grid of triangles (see below).</p>

          <div className="spacer"></div>
          <img src={mpResTIN} />
          <div className="spacer"></div>

          <p>The vector-based TIN can then be converted to a raster-based
          digital surface model (DSM). I performed this conversion using the
          natural neighbors interpolation method, choosing for the output
          raster to have a cell size of one square foot. The resulting DSM then
          represented the estimated height of objects in every square foot
          across the three study areas (see below).</p>

          <div className="spacer"></div>
          <img src={mpResDSM} />
          <div className="spacer"></div>

          <p>ArcGIS has a tool called "area solar radiation" that, combined with
          a given DSM, can calculate the aggregate solar radiation at each point
          over a specified period of time. I used this tool to estimate the
          solar radiation across the three study areas for calendar year 2012.
          The calculated radiation is given in watt-hours per square meter (see
          below).</p>

          <div className="spacer"></div>
          <img src={mpResSolarRadiation} />
          <div className="spacer"></div>

          <p>Before estimating properties of the solar systems that could be
          installed on the study parcels, I first wanted to account for the fact
          that many building rooftops are partially or fully shaded by trees. To
          do this, I made use of aerial imagery from the U.S. Department of
          Agriculture.</p>

          <p>Specifically, in addition to the typical red, green, and blue
          visible color bands, this imagery also included an infrared band
          suitable for calculating the Normalized Difference Vegetation Index
          (NDVI). For biological reasons, plants tend to absorb electromagnetic
          radiation in the visible red light part of the spectrum while
          reflecting near infrared radiation; the NDVI essentially measures the
          difference between these two to identify the amount of vegetation over
          an area.</p>

          <p>The result is a raster with values ranging from -1 to 1, with
          higher values indicating increased levels of vegetation (see below).</p>

          <div className="spacer"></div>
          <img src={mpResNDVI} />
          <div className="spacer"></div>

          <p>With these parts of the analysis completed, I then summed the solar
          radiation across the building footprint(s) on each parcel, ignoring
          any areas (1) where the NDVI indicated the likely presence of
          vegetation and/or (2) where the solar radiation was not above a
          given threshold (based on previous research regarding what would be
          economical for PV system installation). See below for an example.</p>

          <p>I also determined the rooftop <i>area</i> associated with each sum,
          which allowed me to estimate the cost of the PV systems taking into
          account economies of scale.</p>

          <div className="spacer"></div>
          <img src={mpResSuitableArea} />
          <div className="spacer"></div>

          <h4>Results</h4>

          <p>To investigate differences in system costs across different types
          of parcels, I first calculated an energy cost ratio for each system:
          the estimated cost of the system (in dollars) divided by the estimated
          annual energy output of the system (in kWh). Note that this metric
          does not represent the cost of electricity over the lifetime of the
          systems but is rather intended as simple proxy for the purpose of
          comparison.</p>

          <p>The averages of the energy cost ratio for each of the three parcel
          types are shown in the table below. The cost is highest for
          residential parcels, and the differences in cost between residential
          parcels and the other two parcel types are statistically significant.
          Along with some of the other results I present in the thesis, this
          suggests that there may be  something to be gained for the average
          residential parcel – in terms of energy received for the investment
          cost – by pooling funds and installing a system that is optimally
          sited on a well-chosen commercial or community service site.</p>

          <div className="spacer"></div>
          <table>
            <tr>
              <th>Parcel Type</th>
              <th>Number of Parcels</th>
              <th>Average Energy Cost ($/kWh)</th>
            </tr>
            <tr>
              <td>Residential</td>
              <td>1,469</td>
              <td>5.63</td>
            </tr>
            <tr>
              <td>Commercial</td>
              <td>363</td>
              <td>5.24</td>
            </tr>
            <tr>
              <td>Community Service</td>
              <td>82</td>
              <td>5.26</td>
            </tr>
          </table>
          <div className="spacer"></div>

          <p>In terms of access to renewable energy, I found that approximately
          10 percent of residential parcels did not have rooftop areas suitable
          for PV systems. An additional 18 percent of residential parcels had
          rooftop areas suitable for systems of 1 kilowatt or less. Taken
          together, this suggests that nearly a third of the residential
          parcels I considered in the Durham study areas had little or no access
          to solar energy investment opportunities.</p>

          <h4>Conclusion</h4>

          <p>So overall, could community solar reduce the costs of solar
          photovoltaic systems in Durham? I said likely yes, though it depends
          on which two systems are being compared. Looking across the three
          study areas, larger buildings – which tend to be community service or
          commercial buildings – more often exhibited better cost efficiencies.
          They were not always the best, however, and a homeowner with a
          particularly suitable rooftop would not be gaining anything in terms of
          cost efficiency through participation in a community solar venture.</p>

          <p>Could community solar increase access to the benefits of renewable
          solar energy in Durham? I said very likely yes, though the details
          of the community solar implementation would need to be outlined. In
          any case, for the nearly 30 percent of residential parcels with little
          or no suitable rooftop space, the ability to invest in a community
          solar project would be a huge access improvement over the status quo.
          That would also be the case for individuals that live in apartments
          and for homeowners that have suitable rooftop area but are unwilling
          or unable to invest in a full solar photovoltaic system.</p>
        </div>
      </div>
    );
  }
}

class ISONewEngland extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <div className="explanatory_text">
          <div className="spacer"></div>
          <div className="spacer"></div>

          <h3>Exploring ISO New England wind/solar power as it relates to weather and net imports</h3>

          <p>Both as a resident of Massachusetts and as someone who formerly
          worked on climate mitigation for the state, I have an interest in the
          regional New England electricity supply, especially as it pertains to
          renewables and emissions. In this project, I conducted a simple
          exploration of wind and solar power in the <a href="https://www.iso-ne.com/" target="_blank">ISO New England (ISO-NE)</a> resource
          mix for a set of sample days in calendar year 2022.</p>

          <p>Specifically, I retrieved the resource mix for the first day of
          each month in 2022 using ISO-NE's <a href="https://www.iso-ne.com/isoexpress/" target="_blank">ISO Express</a> data
          product. These data points are at the sub-hourly level, which resulted
          in a dataset with 2,357 observations. I then retrieved historical
          weather data measured at Logan International Airport in Boston for
          these same days in 2022 from <a href="https://www.wunderground.com/" target="_blank">Weather Underground</a>, including
          information about wind speed and general conditions. These
          measurements are generally at the hourly level, which resulted in a
          dataset with 360 weather observations.</p>

          <p>I note that these weather data, representing conditions in coastal
          Massachusetts, are likely a better proxy for conditions at wind farms
          in the region than they are for solar farms in the region. In
          addition, for both wind and solar, a single geographic point of
          measurement is of course quite coarse to represent multiple generation
          points.</p>

          <p>This said, with these caveats in mind, I used Microsoft Excel to
          match each row in the power dataset to a row in the weather dataset,
          where a "match" was determined by finding the weather measurement that
          most closely preceded in time each power observation. I then created
          flags to indicate the weather condition (such as cloudy versus fair)
          as well as the season of the year (winter, spring, summer, or fall). A
          copy of the Excel file can be downloaded <a href={isoneExcel} target="_blank">here</a>.</p>

          <h4>Wind Output and Weather</h4>

          <p>With this in hand, I first used Excel to plot the relationship
          between wind power output in megawatts (MW) and wind speed in miles
          per hour (mph) during the sample period (see below; note that the
          values on the horizontal axis should be interpreted as wind speed
          categories, as there are several values missing between 0 mph and 29
          mph).</p>

          <div className="spacer"></div>
          <img src={isoneWindOutputWindSpeed} />
          <div className="spacer"></div>

          <p>For each observed wind speed, the graph shows the individual power
          data points as well as the average (symbolized by an "x") and the
          range of values between the 25th and 75th percentiles (indicated by
          the green vertical boxes; note that the boxes for the categories 25
          mph and 29 mph appear as horizontal lines because there is only one
          observation for each of those two categories).</p>

          <p>As we might expect, the graph generally suggests that the higher
          the wind speed, the greater the wind power in the ISO-NE resource
          mix. A regression of wind output against wind speed and wind gust
          speed (see below) suggests that about 46 percent of the variation in
          wind output during the sample period can be explained by these two
          speed variables alone.</p>

          <p>I also used Excel to plot the relationship between wind power
          output and wind gust speed during the sample period (as above, the
          values on the horizontal axis should be interpreted as wind speed
          categories, as there are values missing between 0 mph and 40 mph).</p>

          <div className="spacer"></div>
          <img src={isoneWindOutputWindGustSpeed} />
          <div className="spacer"></div>

          <p>For each observed wind gust speed, the graph shows the individual
          power data points as well as the average (symbolized by an "x") and
          the range of values between the 25th and 75th percentiles (indicated
          by the orange vertical boxes; note that the box for the category 40
          mph appears as a horizontal line because there is only one observation
          for that category).</p>

          <p>The graph suggests that the higher the wind gust speed, the greater
          the wind power in the ISO-NE resource mix. As noted above, a
          regression of wind output against wind speed and wind gust speed
          suggests that about 46 percent of the variation in wind output during
          the sample period can be explained by those two speed variables. The
          coefficient on wind gust speed is statistically significant even when
          controlling for wind speed. It may be that gusts contribute to
          generation directly and/or that they are correlated with environmental
          and other variables that are favorable for wind production and
          dispatch.</p>

          <h4>Solar Output and Weather</h4>

          <p>The graph below illustrates the relationship between average solar
          power output (yellow bars) and the number of fair weather readings in
          each season (blue circles; for this graph, each "fair weather reading"
          is a time period in the sample in which the weather condition was
          recorded as either "Fair" or "Fair / Windy").</p>

          <div className="spacer"></div>
          <img src={isoneSolarOutputFairWeather} />
          <div className="spacer"></div>

          <p>The graph suggests that solar power was highest in the ISO-NE
          resource mix during the summer, followed by the spring, fall, and
          winter (in that order). The graph also suggests that, at the seasonal
          level, solar generation is highly correlated with the number of fair
          weather readings.</p>

          <p>Although such a relationship appears at this more aggregated level,
          a regression of solar output against indicators for each fair
          condition ("Fair" and "Fair / Windy") at the individual observation
          level suggests that these conditions alone do not explain much of the
          solar variation in the dataset (which is likely partially due to the
          geographic coarseness of the weather data, with one weather station
          serving as a proxy for general conditions in the region). This said,
          the coefficients on the indicators are statistically significant at
          the one percent level.</p>

          <h4>Wind and Solar Output and Net Imports</h4>

          <p>The graph below illustrates the relationship between wind power
          output and net imports (electricity imports minus electricity exports
          across the region's tie lines) during the sample period.</p>

          <div className="spacer"></div>
          <img src={isoneNetImportsWindOutput} />
          <div className="spacer"></div>

          <p>The graph suggests a somewhat negative relationship between these
          two variables (the Pearson correlation coefficient is approximately
          -0.18). This does not necessarily imply that increases in wind power
          "backed out" (i.e., caused a decrease in) net imports on the margin
          during this period, though a regression of net imports on total
          generation, natural gas, nuclear, wind, and solar suggests that this
          type of negative relationship is stronger for wind than it is for
          either natural gas or nuclear.</p>

          <p>ISO-NE's real-time data on CO2 emissions do not include emissions
          estimates for imported electricity "because the resource types
          producing this electricity are unspecified" (see <a href="https://www.iso-ne.com/isoexpress/" target="_blank">ISO Express</a> for
          details). To the extent that wind power did reduce the need for
          electricity imports, it is unclear how such dynamics impacted overall
          CO2 emissions.</p>

          <p>The graph below illustrates the relationship between solar power
          output and net imports (electricity imports minus electricity exports
          across the region's tie lines) during the sample period.</p>

          <div className="spacer"></div>
          <img src={isoneNetImportsSolarOutput} />
          <div className="spacer"></div>

          <p>Even more than in the case of wind, the graph suggests a negative
          relationship between these two variables (the Pearson correlation
          coefficient is approximately -0.42). As above, this does not
          necessarily imply that increases in solar power "backed out" the need
          for net imports on the margin during this period, though a regression
          of net imports on total generation, natural gas, nuclear, wind, and
          solar suggests that such a negative relationship is stronger for solar
          than it is for natural gas, nuclear, or wind. For more information
          about solar power in the ISO-NE resource mix, see <a href="https://www.iso-ne.com/about/what-we-do/in-depth/solar-power-in-new-england-locations-and-impact" target="_blank">Solar Power in New England: Concentration and Impact</a>.</p>

          <p>Also as above, to the extent that solar power did reduce the need
          for electricity imports, it is unclear how such dynamics impacted
          overall CO2 emissions during the sample period as data on the CO2
          intensity of the imports are not available.</p>
        </div>
      </div>
    );
  }
}

class SimulateNetwork extends React.Component {
  render() {
    return (
      <div>
        <div className="explanatory_text">
          <div className="spacer"></div>
          <div className="spacer"></div>
          <h3>Simulating economic production networks</h3>

          <p>This project is an outgrowth of a major component of my <a href="https://dash.harvard.edu/bitstream/handle/1/37372205/Stuart%20Iler%20Dissertation%20Final.pdf" target="_blank">Ph.D. dissertation</a>, which
          simulated networks of industries that were reliant on one another in
          various ways.</p>

          <p>More details coming soon!</p>
        </div>
      </div>
    );
  }
}

function App() {
  return (
    <div className="App">
      <TopBar />
      <Routes>
        <Route path="/" element={<MainPage />}/>
        <Route path="/production_network" element={<ExploreVis graphData={graphData} />}/>
        <Route path="/input_categorization" element={<ExploreCat catData={categoryData} />}/>
        <Route path="/community_solar" element={<CommunitySolar />}/>
        <Route path="/isone_weather" element={<ISONewEngland />}/>
        <Route path="/tetris" element={<PlayTetris />}/>
        <Route path="/production_model" element={<SimulateNetwork />}/>
      </Routes>
    </div>
  );
}

export default App;
